module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Merve Günay - Mimarlık","short_name":"Merve Günay","description":"Mimari tasarım hizmeti, Ofis tasarımlarıi, konsept tasarım, daire ve villa tasarımları, otel ve konut tasarımları","start_url":"/","background_color":"#ffffff","display":"standalone","icon":"src/images/mg.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3a4f7b112d7858ca5895e4f7f597db51"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
