exports.components = {
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-homepage-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/homepage-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-homepage-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-content-pages-hakkimda-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/page-query.tsx?__contentFilePath=/home/safag/merve-gunay/content/pages/hakkimda/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-content-pages-hakkimda-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-content-projelerim-almanya-konut-dis-mekan-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/home/safag/merve-gunay/content/projelerim/almanya-konut-dis-mekan/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-content-projelerim-almanya-konut-dis-mekan-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-content-projelerim-charmains-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/home/safag/merve-gunay/content/projelerim/charmains/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-content-projelerim-charmains-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-content-projelerim-gebze-villa-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/home/safag/merve-gunay/content/projelerim/gebze-villa/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-content-projelerim-gebze-villa-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-content-projelerim-hece-group-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/home/safag/merve-gunay/content/projelerim/hece-group/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-content-projelerim-hece-group-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-content-projelerim-hollanda-daire-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/home/safag/merve-gunay/content/projelerim/hollanda-daire/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-content-projelerim-hollanda-daire-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-content-projelerim-metro-city-ekpa-insaat-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/home/safag/merve-gunay/content/projelerim/metro-city-ekpa-insaat/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-content-projelerim-metro-city-ekpa-insaat-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-content-projelerim-milas-villa-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/home/safag/merve-gunay/content/projelerim/milas-villa/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-content-projelerim-milas-villa-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-content-projelerim-yatak-odasi-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/home/safag/merve-gunay/content/projelerim/yatak-odasi/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-content-projelerim-yatak-odasi-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-projects-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/projects-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-projects-query-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

